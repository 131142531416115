import type { User as AuthUser } from "firebase/auth"

import { EXTENSION_ID } from "./constants"

export const isQuiltInternalUser = (user: AuthUser) => {
  if (!user.email) {
    return false
  }

  const domain = user.email.split("@")[1]
  return domain === "quilt.app"
}

function randomBytes(nBytes: number): Uint8Array {
  const bytes = new Uint8Array(nBytes)
  crypto.getRandomValues(bytes)
  return bytes
}

// https://github.com/firebase/firebase-js-sdk/blob/766a53ea97f8af432f5cc5fd0214989cbbd0391e/packages/firestore/src/util/misc.ts#L34
export const randomId = (): string => {
  // Alphanumeric characters
  const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
  // The largest byte value that is a multiple of `char.length`.
  const maxMultiple = 248

  let autoId = ""
  const targetLength = 20
  while (autoId.length < targetLength) {
    const bytes = randomBytes(40)
    for (let i = 0; i < bytes.length; ++i) {
      if (autoId.length < targetLength && bytes[i] < maxMultiple) {
        autoId += chars.charAt(bytes[i] % chars.length)
      }
    }
  }
  return autoId
}

export const shorten = (text: string, maxLength: number): string => {
  if (text && text.length > maxLength) {
    return text.slice(0, maxLength - 3) + "..."
  }
  return text
}

export const sleep = (sleepMs: number): Promise<void> =>
  new Promise((res) => setTimeout(res, sleepMs))

export const mapValues = <T, U = T>(
  obj: Record<string, T>,
  fn: (value: T) => U,
): Record<string, U> => {
  const result: Record<string, U> = {}
  for (const key in obj) {
    result[key] = fn(obj[key])
  }
  return result
}

export const replaceWhere = <T>(
  arr: T[],
  newValue: T,
  predicate: (value: T) => boolean,
): T[] => {
  const index = arr.findIndex(predicate)
  if (index === -1) {
    return arr
  }
  const result = [...arr]
  result[index] = newValue
  return result
}

export const updateWhere = <T>(
  arr: T[],
  update: (value: T) => T,
  predicate: (value: T) => boolean,
): T[] => {
  const index = arr.findIndex(predicate)
  if (index === -1) {
    return arr
  }
  const result = [...arr]
  result[index] = update(result[index])
  return result
}

// Get the values in left with matching keys replaced by the corresponding value
// in right.replaced by the values in right, where the keys match.
export const replaceMany = <T>(
  left: T[],
  right: T[],
  getKey: (value: T) => string,
): T[] => {
  const rightMap: Record<string, T> = {}
  for (const value of right) {
    rightMap[getKey(value)] = value
  }
  return left.map((value) => {
    const key = getKey(value)
    return rightMap[key] ?? value
  })
}

export const unique = <T extends string>(arr: T[]): T[] => {
  const seen: Record<string, boolean> = {}
  const result: T[] = []
  for (const v of arr) {
    if (!seen[v]) {
      seen[v] = true
      result.push(v)
    }
  }
  return result
}

export const uniqueByField = <
  K extends string,
  T extends Record<K, string | number>,
>(
  arr: T[],
  fieldName: K,
): T[] => {
  const seen: Record<string | number, boolean> = {}
  const result: T[] = []
  for (const v of arr) {
    const key = v[fieldName]
    if (!seen[key]) {
      seen[key] = true
      result.push(v)
    }
  }
  return result
}

export const uniqueByKey = <T>(arr: T[], getKey: (t: T) => string): T[] => {
  const seen: Record<string, boolean> = {}
  const result: T[] = []
  for (const v of arr) {
    const key = getKey(v)
    if (!seen[key]) {
      seen[key] = true
      result.push(v)
    }
  }
  return result
}

// Get the unique values in an array by a key, with a priority function used
// to determine which value to keep when there are duplicates.
// The returned values are sorted by priority.
export const uniqueByKeyWithPriority = <T>(
  arr: T[],
  getKey: (t: T) => string,
  getPriority: (t: T) => number,
): T[] => {
  const seen: { [key in string]: { item: T; priority: number } } = {}
  for (const v of arr) {
    const key = getKey(v)
    const priority = getPriority(v)
    if (!(key in seen) || seen[key].priority < priority) {
      seen[key] = { item: v, priority: priority }
    }
  }
  return Object.values(seen)
    .toSorted((a, b) => a.priority - b.priority)
    .map((v) => v.item)
}

export const replaceOrAppendByKey = <T>(
  arr: T[],
  value: T,
  getKey: (t: T) => string,
): T[] => {
  const result: T[] = []
  const inputKey = getKey(value)
  let replaced = false

  for (const v of arr) {
    if (getKey(v) === inputKey) {
      result.push(value)
      replaced = true
    } else {
      result.push(v)
    }
  }

  if (!replaced) {
    result.push(value)
  }
  return result
}

export const toHumanReadableString = (
  date: FirebaseFirestore.Timestamp,
  options?: { showTime: boolean },
): string => {
  return new Date(date.seconds * 1000).toLocaleDateString(undefined, {
    ...(options?.showTime && {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }),
    year: "numeric",
    month: "short",
    day: "numeric",
  })
}

export function formatNumber(num: number): string {
  return num < 1e3
    ? `${num}`
    : num < 1e6
      ? `${(num / 1e3).toFixed(1)}K`
      : num < 1e9
        ? `${(num / 1e6).toFixed(1)}M`
        : `${(num / 1e9).toFixed(1)}B`
}

export const hasExtension = (): boolean => {
  if (!window.chrome?.runtime) {
    return false
  }

  try {
    chrome.runtime.connect(EXTENSION_ID ?? "", {})
    return true
  } catch {
    return false
  }
}

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

export const sortByCreatedAtAsc = <
  T extends { created_at: FirebaseFirestore.Timestamp },
>(
  a: T,
  b: T,
) => a.created_at._compareTo(b.created_at)

export const sortByCreatedAtDesc = <
  T extends { created_at: FirebaseFirestore.Timestamp },
>(
  a: T,
  b: T,
) => b.created_at._compareTo(a.created_at)

export const sortByUpdatedAtDesc = <
  T extends { updated_at: FirebaseFirestore.Timestamp },
>(
  a: T,
  b: T,
) => b.updated_at._compareTo(a.updated_at)

export const getLexicalSorter =
  <T extends string>(field: T) =>
  (
    aObj: Record<T, string | null | undefined>,
    bObj: Partial<Record<T, string | null | undefined>>,
  ) => {
    const a = aObj[field] ?? ""
    const b = bObj[field] ?? ""
    return a < b ? -1 : a > b ? 1 : 0
  }

export const sortStringsByLength = (a: string, b: string) => {
  if (a.length > b.length) return 1
  if (a.length < b.length) return -1
  if (a > b) return 1
  if (a < b) return -1
  return 0
}

/**
 * Splits an array into batches of a specified size.
 * @param items The array to be batched.
 * @param batchSize The maximum size of each batch.
 * @returns An array of batches, where each batch is an array of items.
 */
export function batchArray<T>(items: T[], batchSize: number): T[][] {
  const batches: T[][] = []
  for (let i = 0; i < items.length; i += batchSize) {
    batches.push(items.slice(i, i + batchSize))
  }
  return batches
}

export function partitionArray<T>(
  items: T[],
  predicate: (item: T) => boolean,
): [T[], T[]] {
  const left: T[] = []
  const right: T[] = []
  for (const item of items) {
    if (predicate(item)) {
      left.push(item)
    } else {
      right.push(item)
    }
  }
  return [left, right]
}

export function pluralize(
  count: number,
  singular: string,
  plural?: string,
): string {
  if (count <= 1) {
    return singular
  }
  if (!plural) {
    return singular + "s"
  }
  return plural
}

export async function openWindow(
  url: string,
  target: string = "_blank",
  features: string = "noopener,noreferrer",
): Promise<Window> {
  const isSafari =
    !!window.safari ||
    /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

  let result: Window | null
  if (isSafari) {
    result = await new Promise((resolve) => {
      setTimeout(() => resolve(window.open(url, target, features)), 0)
    })
  } else {
    result = window.open(url, target, features)
  }
  if (!result) {
    throw new Error("Failed to open window")
  }
  result.focus()
  return result
}
