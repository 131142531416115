import type { SyncNode } from "../../sources/types"

export interface FilePickerState {
  nodes: SyncNode[]
  currentPath: string | null
  searchQuery: string
  isLoading: boolean
  error?: string
  cursor?: string
  hasMore: boolean
  changedFiles: Map<string, "INCLUDE" | "EXCLUDE">
}

export const initialState: FilePickerState = {
  nodes: [],
  currentPath: null,
  searchQuery: "",
  isLoading: false,
  hasMore: false,
  changedFiles: new Map(),
}
