import Checkbox from "antd/es/checkbox"

import { VOID_FUNCTION } from "../../constants"
import { useActiveUserAuthorizationFromContext } from "../../contexts/ActiveUserAuthorizationContext"
import useErrorPopup from "../../hooks/useErrorPopup"
import type { AnswerQuestionnaireJob } from "../../types/jobs"
import ChatReferenceList from "../ChatReferenceList"
import Discussions from "../Discussions/Discussions"
import FeedFooterBadge from "./FeedFooterBadge"
import QuestionAnswerBlock from "./QuestionAnswerBlock"
import type { AnswerWithDiscussion } from "./types"
import { updateAnswer } from "./utils"

interface QuestionnaireReviewFeedCardProps {
  answer: AnswerWithDiscussion
  isSelected: boolean
  loading: boolean
  onSelect: (answer: AnswerWithDiscussion, event: React.MouseEvent) => void
  job: AnswerQuestionnaireJob
  setUpdatingAnswer: (value: boolean) => void
  discussionsError?: Error
  focused?: boolean
}

const QuestionnaireReviewFeedCard: React.FC<
  QuestionnaireReviewFeedCardProps
> = ({
  answer,
  job,
  discussionsError,
  isSelected,
  onSelect,
  focused,
  loading,
  setUpdatingAnswer,
}) => {
  const answerLocation = answer.primary_answer.location
  const { activeGroupOid } = useActiveUserAuthorizationFromContext()

  const { handleError, handleSuccess } = useErrorPopup()

  const handleCardClick = (event: React.MouseEvent) => {
    onSelect(answer, event)
  }

  const handleUpdateAnswer = async (
    primaryText: string,
    secondaryText: string,
  ) => {
    setUpdatingAnswer(true)
    try {
      await updateAnswer({
        answer,
        job_oid: job.oid,
        primaryText,
        secondaryText,
      })
      handleSuccess("Answers updated successfully!")
    } catch (error) {
      handleError({ error, prefix: "Couldn't update answers" })
    } finally {
      setUpdatingAnswer(false)
    }
  }

  return (
    <div
      className={`border-gray-25 flex items-start rounded-md border-b border-t py-4 pl-2 pr-4 transition-all duration-200 ${focused ? "bg-purple-50" : ""}`}
    >
      <div
        className={`mr-3 flex w-full flex-1 rounded-lg border-2 bg-white shadow-sm transition-all duration-300 ${isSelected ? "border-primary" : "border-gray-200"}`}
      >
        <div
          onClick={handleCardClick}
          className={`flex w-16 min-w-[4rem] max-w-[4rem] cursor-pointer items-center justify-center overflow-hidden rounded-bl-lg rounded-tl-lg ${isSelected ? "bg-purple-100" : "bg-gray-25 hover:bg-gray-50"}`}
        >
          <Checkbox style={{ transform: "scale(1.2)" }} checked={isSelected} />
        </div>
        <div className="flex w-full flex-col border-l border-gray-100">
          <div className="grow p-3">
            <QuestionAnswerBlock
              jobOid={job.oid}
              answerObj={answer}
              onUpdateAnswer={handleUpdateAnswer}
              loading={loading}
            />
            <ChatReferenceList
              references={answer.references}
              className="mt-4"
            />
          </div>
          {answer.last_assigned_to ? (
            <div className="bg-gray-25 mt-2 flex items-center p-3 text-xs text-gray-500">
              <FeedFooterBadge answer={answer} />
            </div>
          ) : null}
        </div>
      </div>
      <div className="w-[30%] min-w-[20rem] max-w-[30rem]">
        {answerLocation && (
          <Discussions
            kind="SHEET"
            group_oid={activeGroupOid}
            discussions={answer.discussions}
            job_id={job.oid}
            location={answerLocation}
            discussionsError={discussionsError}
            onClickLocation={VOID_FUNCTION}
            useFeedCTA
            hideTitle
          />
        )}
      </div>
    </div>
  )
}

export default QuestionnaireReviewFeedCard
