export const GROUP_SETTINGS_COLLECTION = "group_settings"

export interface QuiltFlags {
  showNotion?: boolean
  showGong?: boolean
  showZoom?: boolean
  showAtlassian?: boolean
  showLiveAssistant?: boolean
  showLiveCoaching?: boolean
  showCarbonOnedrive?: boolean
  showCarbonNotion?: boolean
  showCarbonConfluence?: boolean
  showCarbonSharepoint?: boolean
  showQuestionnaireAnswersPages?: boolean
  showSlackSource?: boolean
  showPdfQuestionnaires?: boolean
  showDocxQuestionnaires?: boolean
  showLiveNotetaker?: boolean
  showPlaybooks?: boolean
  enableSalesforceConnection?: boolean
  showBrowserAgentInExtension?: boolean
  usePostCarbonIntegrations?: boolean
}

export interface OIDCSSOConfig {
  // provider_id is implicitly oidc.quilt.groups.<group_oid>
  // Client Secret is not stored in the database.
  client_id: string
  issuer: string
}

export interface Group {
  oid: string
  name: string
  description?: string
  image_url?: string
  created_at: FirebaseFirestore.Timestamp
  updated_at: FirebaseFirestore.Timestamp
  company_name?: string
  domain_names?: string[]

  // TODO(mgraczyk): Move this to a user/group flags object.
  flags?: QuiltFlags
  stripe_customer_id?: string
  is_disabled?: boolean

  // Configuration related to how users can sign in.
  auth_config?: {
    // Default is "ALL_ALLOWED"
    auth_methods?: "ALL_ALLOWED" | "RESTRICTED"

    // If auth_methods is "RESTRICTED", users must use one of these methods.
    // If auth_methods is "ALL_ALLOWED", this field is ignored.
    // Quilt impersonation is always allowed.
    allowed_auth_methods: {
      sso?: boolean
      password?: boolean
      google_oauth?: boolean
      microsoft_oauth?: boolean
    }

    // If set, this config is used for OIDC SSO.
    oidc?: OIDCSSOConfig
  }
}

// User controlled settings for the entire group.
// /group_settings/{group_oid}
export interface GroupSettings {
  oid: string
  import_questionnaire_on_complete?: boolean
}

// See auth/types.py for details.
export type GroupRole = "ADMIN" | "MEMBER" | "CONTENT_MANAGER" | "VIEWER"
export type InternalRole = GroupRole | "QUILT_ADMIN" | "ANONYMOUS_SLACK_USER"

export interface GroupInvite {
  oid: string
  creator_uid: string
  creator_email: string
  created_at: FirebaseFirestore.Timestamp
  invitee_email: string
  group_oid: string
  group_name: string
  role: GroupRole
  status: "PENDING" | "ACCEPTED" | "CANCELED"
}

export interface GroupMembership {
  oid: string
  uid: string
  role: GroupRole
  email: string
  created_at: FirebaseFirestore.Timestamp
  photoURL?: string
}

export interface AddGroupMemberArgs {
  group_oid: string
  email: string
  role: GroupRole
}

export interface RemoveGroupMemberArgs {
  uid: string
  group_oid: string
}

export interface UpdateGroupMemberArgs {
  uid: string
  group_oid: string
  role: GroupRole
}

export interface AdminUpdateGroupEnabledArgs {
  group_oid: string
  is_disabled: boolean
}

export type CreateGroupArgs = Pick<
  Group,
  "name" | "image_url" | "domain_names" | "description"
>

export type CreateGroupWithStripeCustomerIdArgs = CreateGroupArgs & {
  stripe_customer_id: string
}

export type UpdateGroupArgs = Pick<Group, "oid" | "name" | "company_name">

export type CreateGroupInviteArgs = Pick<
  GroupInvite,
  "invitee_email" | "group_oid" | "role"
>
export type AcceptGroupInviteArgs = Pick<GroupInvite, "oid">

export type CreateGroupInviteResult =
  | {
      result: "SENT" | "RESENT"
      invite: GroupInvite
    }
  | {
      result: "ALREADY_MEMBER"
      uid: string
    }

export type RemoveGroupInviteArgs = AcceptGroupInviteArgs
export interface QuiltAdminUpdateGroupArgs {
  oid: string
  name?: Group["name"]
  company_name?: Group["company_name"]
  image_url?: Group["image_url"]
  description?: Group["description"]
  domain_names?: Group["domain_names"]
  flags?: Group["flags"]
}

export interface QuiltAdminAddGroupMemberArgs extends AddGroupMemberArgs {
  allowCreateUser: boolean
}

export interface SwitchGroupArgs {
  groupOid: string
}
