import { Alert } from "antd"
import { useCallback } from "react"
import useDrivePicker from "react-google-drive-picker"

import BoxLogo from "../assets/img/box_logo.svg"
import ConfluenceLogo from "../assets/img/confluence_logo.svg"
import DriveLogo from "../assets/img/drive_logo.svg"
import DropboxLogo from "../assets/img/dropbox_logo.svg"
import HighspotLogo from "../assets/img/highspot_logo.svg"
import IntercomLogo from "../assets/img/intercom_logo.svg"
import NotionLogo from "../assets/img/notion_logo.svg"
import OneDriveLogo from "../assets/img/onedrive_logo.svg"
import SeismicLogo from "../assets/img/seismic_logo.svg"
import SharepointLogo from "../assets/img/sharepoint.svg"
import UnknownLogo from "../assets/img/unknown_logo.svg"
import ZendeskLogo from "../assets/img/zendesk_logo.svg"
import ZoomLogo from "../assets/img/zoom_logo.svg"
import { VOID_FUNCTION } from "../constants"
import { useActiveGroup } from "../contexts/useActiveGroup"
import {
  ATLASSIAN_PROVIDER,
  GONG_PROVIDER,
  GOOGLE_PROVIDER,
  NOTION_PROVIDER,
  SALESFORCE_PROVIDER,
  ZOOM_PROVIDER,
} from "../credentials/providerTypes"
import useSources from "../hooks/useSources"
import useUserCredential from "../hooks/useUserCredential"
import CarbonIntegrationsBlock from "./CarbonIntegrationsBlock"
import FilePickerIntegrationBlock from "./FilePickerIntegrationBlock"
import GongIntegrationBlock from "./GongIntegrationBlock"
import IntegrationBlock from "./IntegrationBlock"
import SalesforceIntegrationBlock from "./SalesforceIntegrationBlock"
import SlackBotIntegrationBlock from "./SlackBotIntegrationBlock"
import SlackSourceIntegrationBlock from "./SlackSourceIntegrationBlock"

const EXTRA_INTEGRATION_DETAILS = [
  [OneDriveLogo, "OneDrive"],
  [SharepointLogo, "Sharepoint"],
  [SeismicLogo, "Seismic"],
  [IntercomLogo, "Intercom Articles"],
  [DropboxLogo, "Dropbox"],
  [BoxLogo, "Box"],
  [HighspotLogo, "Highspot"],
  [ZendeskLogo, "Zendesk"],
  [UnknownLogo, "Request an Integration"],
]

const IntegrationManager: React.FC = () => {
  const {
    loadingError,
    actionError,
    loading: userCredentialLoading,
    userCredentials,
    removeCredentialEntry,
    connectGong,
    connectGoogleDrive,
    connectSalesforce,
    connectZoom,
    connectNotion,
    connectAtlassian,
  } = useUserCredential()

  const [sources, sourcesLoading, sourcesError] = useSources()

  const { flags } = useActiveGroup()
  useDrivePicker()

  const credentialsByProvider = Object.fromEntries(
    userCredentials.map((credential) => [credential.provider, credential]),
  )

  const onDisconnectCredential = useCallback(
    async (provider: string) => {
      if (!credentialsByProvider[provider]) return
      await removeCredentialEntry(credentialsByProvider[provider])
    },
    [removeCredentialEntry, credentialsByProvider],
  )

  const fullPageError = loadingError ?? sourcesError
  if (fullPageError) {
    return (
      <Alert
        className="mb-4"
        message="Error loading integrations"
        description={fullPageError.message}
        type="error"
        showIcon
      />
    )
  }

  const extraIntegrations = EXTRA_INTEGRATION_DETAILS.map(([logo, name]) => (
    <IntegrationBlock
      key={name}
      loading={false}
      name={name}
      connected={false}
      onConnect={VOID_FUNCTION}
      onDisconnect={VOID_FUNCTION}
      logo={logo}
      notEnabled={true}
    />
  ))

  return (
    <div>
      <h2>Your Integrations</h2>
      {actionError && (
        <Alert
          className="mb-4"
          message="Error modifying integration"
          description={actionError.message}
          type="error"
          showIcon
        />
      )}
      <div className="grid gap-4 lg:grid-cols-2 xl:grid-cols-3">
        <IntegrationBlock
          loading={userCredentialLoading}
          name="Google Drive"
          connected={!!credentialsByProvider[GOOGLE_PROVIDER]}
          onConnect={connectGoogleDrive}
          onDisconnect={() => onDisconnectCredential(GOOGLE_PROVIDER)}
          logo={DriveLogo}
        />
        <SlackBotIntegrationBlock integrationsLoading={userCredentialLoading} />

        <SlackSourceIntegrationBlock
          notEnabled={!flags.showSlackSource}
          source={sources?.find((source) => source.kind === "SLACK")}
          loading={sourcesLoading || userCredentialLoading}
        />

        <GongIntegrationBlock
          notEnabled={!flags.showGong}
          source={sources?.find((source) => source.kind === "GONG")}
          loading={sourcesLoading || userCredentialLoading}
          credential={credentialsByProvider[GONG_PROVIDER]}
          connectCredential={connectGong}
          disconnectCredential={() => onDisconnectCredential(GONG_PROVIDER)}
        />
        <FilePickerIntegrationBlock
          name="Notion"
          logo={NotionLogo}
          sourceKind="NOTION"
          notEnabled={!(flags.showCarbonNotion || flags.showNotion)}
          source={sources?.find((source) => source.kind === "NOTION")}
          loading={sourcesLoading || userCredentialLoading}
          credential={credentialsByProvider[NOTION_PROVIDER]}
          connectCredential={connectNotion}
          disconnectCredential={() => onDisconnectCredential(NOTION_PROVIDER)}
        />

        {flags.usePostCarbonIntegrations ? (
          <>
            <FilePickerIntegrationBlock
              name="Confluence"
              logo={ConfluenceLogo}
              sourceKind="CONFLUENCE"
              notEnabled={!(flags.showAtlassian || flags.showCarbonConfluence)}
              source={sources?.find((source) => source.kind === "CONFLUENCE")}
              loading={sourcesLoading || userCredentialLoading}
              credential={credentialsByProvider[ATLASSIAN_PROVIDER]}
              connectCredential={connectAtlassian}
              disconnectCredential={() =>
                onDisconnectCredential(ATLASSIAN_PROVIDER)
              }
            />
          </>
        ) : (
          <>
            <CarbonIntegrationsBlock
              integrationName="CONFLUENCE"
              logo={ConfluenceLogo}
              notEnabled={!(flags.showAtlassian || flags.showCarbonConfluence)}
              source={sources?.find(
                (source) => source.kind === "CARBON_CONFLUENCE",
              )}
              sourcesLoading={sourcesLoading}
            />
          </>
        )}

        <IntegrationBlock
          loading={userCredentialLoading}
          name="Zoom"
          connected={!!credentialsByProvider[ZOOM_PROVIDER]}
          onConnect={connectZoom}
          onDisconnect={() => onDisconnectCredential(ZOOM_PROVIDER)}
          logo={ZoomLogo}
          notEnabled={!flags.showZoom}
        />

        <SalesforceIntegrationBlock
          notEnabled={!flags.enableSalesforceConnection}
          loading={userCredentialLoading}
          credential={credentialsByProvider[SALESFORCE_PROVIDER]}
          connectCredential={connectSalesforce}
          disconnectCredential={() =>
            onDisconnectCredential(SALESFORCE_PROVIDER)
          }
        />

        {extraIntegrations}
      </div>
    </div>
  )
}
export default IntegrationManager
