import { createRoot } from "react-dom/client"

import ExternalLink from "./components/ExternalLink"
import QuiltLogo from "./components/QuiltLogo"

const CarbonConnectDataWrapper: React.FC = () => {
  const queryParams = new URLSearchParams(window.location.search)
  const integrationNameParam = queryParams.get("integrationName")

  const capitalizeFirstLetter = (val: string | undefined) => {
    if (!val) {
      return val
    }
    return val.charAt(0).toUpperCase() + String(val).slice(1)
  }

  const integrationName = capitalizeFirstLetter(
    integrationNameParam?.toLocaleLowerCase(),
  )

  return (
    <div className="mt-[100px] flex flex-col items-center justify-center gap-2">
      <QuiltLogo width="200px" />
      <span className="text-gray-800">
        The &quot;{integrationName}&quot; integration is undergoing maintenance
        as we switch integration providers.
        <br />
        Please contact support on slack or at support@quilt.app if you need
        assistance.
      </span>
      <ExternalLink href="/group/settings/integrations" target="">
        Back to Quilt
      </ExternalLink>
    </div>
  )
}

export const renderCarbonConnect = () => {
  createRoot(document.getElementById("root")!).render(
    <CarbonConnectDataWrapper />,
  )
}
