import type { SyncNode } from "../../sources/types"
import type { FilePickerState } from "./state"

export type Action =
  | {
      type: "ADD_NODES"
      payload: { items: SyncNode[]; nextCursor?: string; hasMore: boolean }
    }
  | { type: "TOGGLE_SYNC"; oid: string }
  | { type: "RESET_CHANGES" }
  | { type: "SET_LOADING"; payload: boolean }
  | { type: "SET_ERROR"; payload?: string }
  | { type: "SET_CURRENT_PATH"; external_id: string }

export function reducer(
  state: FilePickerState,
  action: Action,
): FilePickerState {
  switch (action.type) {
    case "ADD_NODES": {
      const existingOids = new Set(state.nodes.map((node) => node.oid))
      const uniqueNewNodes = action.payload.items.filter(
        (node) => !existingOids.has(node.oid),
      )

      return {
        ...state,
        nodes: [...state.nodes, ...uniqueNewNodes],
        cursor: action.payload.nextCursor,
        hasMore: action.payload.hasMore,
      }
    }

    case "TOGGLE_SYNC": {
      const node = state.nodes.find((n) => n.oid === action.oid)
      if (!node) return state

      const newChangedFiles = new Map(state.changedFiles)
      const currentSelection = newChangedFiles.has(node.oid)
        ? newChangedFiles.get(node.oid)
        : node.sync_selection

      // Only set EXCLUDE if the node was previously INCLUDE
      if (currentSelection === "INCLUDE") {
        newChangedFiles.set(node.oid, "EXCLUDE")
      } else {
        newChangedFiles.set(node.oid, "INCLUDE")
      }

      return {
        ...state,
        changedFiles: newChangedFiles,
      }
    }

    case "RESET_CHANGES": {
      return {
        ...state,
        changedFiles: new Map(),
      }
    }

    case "SET_CURRENT_PATH":
      return { ...state, currentPath: action.external_id }

    case "SET_LOADING":
      return { ...state, isLoading: action.payload }

    case "SET_ERROR":
      return { ...state, error: action.payload }

    default:
      return state
  }
}
