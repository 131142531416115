import { useCallback, useRef } from "react"

import { getTextToSpeech } from "../../live/api"
import type { LiveUIEvent } from "../../live/types"
import { playMp3FromArrayBuffer } from "../../live/utils"

interface PlaybackTask {
  text: string
  promise: Promise<void>
}

const useTtsPlayback = (enabled?: boolean) => {
  const ttsPlaybackTask = useRef<PlaybackTask>(undefined)

  const onEvent = useCallback(
    (event: LiveUIEvent) => {
      if (!enabled) {
        return
      }
      if (
        event.kind === "LIVE_UI_CARD_UPDATE" &&
        event.card.kind === "ANSWERED_QUESTION" &&
        event.card.answer &&
        event.card.answer_state === "DONE"
      ) {
        const text = event.card.answer.primary_answer
        const prevTask = ttsPlaybackTask.current
        if (text === prevTask?.text) {
          return
        }
        ttsPlaybackTask.current = {
          text,
          promise: (async () => {
            console.log("getting transcript")
            const audioMp3 = await getTextToSpeech(text)
            if (prevTask) {
              // Await previous task.
              console.log("awaiting previous")
              await prevTask.promise
            }
            console.log("playing audio", audioMp3)
            await playMp3FromArrayBuffer(audioMp3)
          })(),
        }
      }
    },
    [enabled],
  )
  return { onEvent }
}
export default useTtsPlayback
