import { Breadcrumb, Button, Checkbox, Skeleton, Table } from "antd"
import Modal from "antd/es/modal"
import { FileIcon, FolderIcon, Search } from "lucide-react"
import { useState } from "react"
import InfiniteScroll from "react-infinite-scroll-component"

import useErrorPopup from "../../hooks/useErrorPopup"
import { updateSyncNodes } from "../../integrations/api"
import type { Source, SyncNode } from "../../sources/types"
import { sleep, toHumanReadableString } from "../../utils"
import { useIntegrationPicker } from "./useIntegrationPicker"

interface FilePickerProps {
  isModal?: boolean
  onCancel?: () => void
  source?: Source
  open: boolean
}

const columns = (
  onSyncChange: (oid: string) => void,
  onFolderClick: (external_id: string) => void,
) => [
  {
    title: "FILE NAME",
    key: "name",
    render: (record: SyncNode) => (
      <div className="flex items-center gap-3">
        {record.is_folder ? (
          <Button
            type="text"
            onClick={() => onFolderClick(record.external_id)}
            className="flex items-center gap-3 !p-0 hover:text-blue-600"
            icon={<FolderIcon className="h-4 w-4 !text-yellow-500" />}
          >
            <span className="truncate">{record.title}</span>
          </Button>
        ) : (
          <>
            <FileIcon className="h-4 w-4 !text-gray-400" />
            <span className="truncate">{record.title}</span>
          </>
        )}
      </div>
    ),
  },
  {
    title: "CREATED AT",
    key: "createdAt",
    width: 200,
    render: (record: SyncNode) => (
      <span className="text-sm text-gray-500">
        {toHumanReadableString(record.created_at)}
      </span>
    ),
  },
  {
    title: "SYNCING",
    key: "syncing",
    width: 100,
    render: (record: SyncNode) => (
      <Checkbox
        checked={record.sync_selection === "INCLUDE"}
        onChange={() => onSyncChange(record.oid)}
        className="text-green-600"
      />
    ),
  },
]

const FilePicker: React.FC<FilePickerProps> = ({
  isModal,
  onCancel,
  source,
  open,
}) => {
  const { handleSuccess, handleError } = useErrorPopup()
  const [updating, setUpdating] = useState(false)
  const [searchQuery, setSearchQuery] = useState("")

  const {
    state,
    loadMore,
    handleFolderClick,
    handleSyncChange,
    cancelChanges,
  } = useIntegrationPicker({
    source,
    open,
  })

  // Apply local modifications and filtering
  const modifiedNodes = state.nodes.map((node) => {
    if (state.changedFiles.has(node.oid)) {
      return {
        ...node,
        sync_selection: state.changedFiles.get(node.oid),
      }
    }
    return node
  })

  const searchQueryLower = searchQuery.toLowerCase()
  const visibleNodes = modifiedNodes.filter((n) => {
    return (
      n.parent_external_id === (state.currentPath || null) &&
      (searchQueryLower === "" ||
        (n.title ?? "").toLowerCase().includes(searchQueryLower))
    )
  })

  const getBreadcrumbItems = () => {
    const path = []
    let currentId = state.currentPath

    while (currentId) {
      const current = state.nodes.find((n) => n.external_id === currentId)
      if (!current) break
      path.push({ external_id: current.external_id, name: current.title })
      currentId = current.parent_external_id
    }

    return [{ external_id: "", name: "All Files" }, ...path.reverse()]
  }

  const onUpdateSyncedFiles = async () => {
    if (!source) return
    setUpdating(true)
    try {
      await updateSyncNodes({
        source_oid: source.oid,
        update_nodes: Array.from(state.changedFiles).map(
          ([oid, sync_selection]) => ({
            oid,
            sync_selection,
          }),
        ),
      })
      handleSuccess("Updated selection, files will sync in the background")
      await sleep(500)
      cancelChanges()
    } catch (error) {
      handleError({ error, prefix: "Couldn't update synced files" })
    } finally {
      setUpdating(false)
    }
  }

  let contents
  if (!open) {
    contents = null
  } else if (!source?.nodes_synced_at) {
    contents = (
      <div>
        <h2>Integration is still being set up</h2>
        <Skeleton active loading />
      </div>
    )
  } else if (state.isLoading && state.nodes.length === 0) {
    contents = (
      <div>
        <h2>Loading...</h2>
        <Skeleton active loading />
      </div>
    )
  } else {
    contents = (
      <>
        <div className="flex items-center justify-between p-4">
          <div className="flex items-center gap-3">
            <div className="rounded-lg bg-gray-100 p-2">
              <FileIcon className="h-6 w-6" />
            </div>
            <h2 className="text-xl font-medium">Synced Files</h2>
          </div>
          <div className="flex items-center gap-2">
            <div className="relative w-96">
              <Search className="absolute left-3 top-2 mr-4 h-4 w-4 text-gray-400" />
              <input
                type="text"
                placeholder="Search"
                className="w-full rounded-md bg-gray-100 py-1.5 pl-9 pr-4"
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="border-b py-2">
          <Breadcrumb
            separator={<span className="translate-y-3">/</span>}
            items={getBreadcrumbItems().map((item) => ({
              title: (
                <Button
                  type="text"
                  onClick={() => handleFolderClick(item.external_id)}
                  className="hover:text-blue-600"
                >
                  {item.name}
                </Button>
              ),
            }))}
          />
        </div>

        {/* File List */}
        <div className="flex-1 overflow-auto" id="scrollableDiv">
          <InfiniteScroll
            dataLength={visibleNodes.length}
            next={loadMore}
            hasMore={state.hasMore}
            loader={
              <div className="p-4 text-center text-gray-500">
                Loading more files...
              </div>
            }
            scrollableTarget="scrollableDiv"
          >
            <Table
              dataSource={visibleNodes}
              columns={columns(handleSyncChange, handleFolderClick)}
              pagination={false}
              rowKey="oid"
              size="small"
              loading={state.isLoading}
            />
          </InfiniteScroll>
        </div>

        <div className="flex justify-between border-t p-4">
          <Button
            type="primary"
            onClick={onUpdateSyncedFiles}
            disabled={state.changedFiles.size === 0 || updating}
          >
            {updating
              ? "Updating..."
              : `Update ${state.changedFiles.size} files`}
          </Button>
          <Button
            type="default"
            onClick={cancelChanges}
            disabled={state.changedFiles.size === 0 || updating}
          >
            Cancel changes
          </Button>
        </div>
      </>
    )
  }

  if (isModal) {
    return (
      <Modal
        open={open}
        footer={false}
        centered
        width={800}
        closeIcon
        onClose={onCancel}
        maskClosable
        onCancel={onCancel}
        className="rounded-lg"
      >
        <div className="flex h-[600px] w-full flex-col sm:max-h-full">
          {contents}
        </div>
      </Modal>
    )
  }

  return (
    <div className="w-max-full flex h-[600px] w-[800px] flex-col sm:max-h-full">
      {contents}
    </div>
  )
}

export default FilePicker
