import { createRoot } from "react-dom/client"

import App from "./App"
import "./main.css"
import { renderCarbonConnect } from "./renderCarbonConnect"
import { setupSentry } from "./sentry"

setupSentry()

if (window.location.pathname.startsWith("/carbon-connect")) {
  renderCarbonConnect()
} else {
  createRoot(document.getElementById("root")!).render(<App />)
}
