import { Timestamp } from "firebase/firestore"
import { useCallback, useEffect, useReducer } from "react"

import useErrorPopup from "../../hooks/useErrorPopup"
import { getNodes } from "../../sources/api"
import type { Source } from "../../sources/types"
import { reducer } from "./reducer"
import { initialState } from "./state"

interface UseIntegrationPickerProps {
  source?: Source
  open: boolean
}

export const useIntegrationPicker = ({
  source,
  open,
}: UseIntegrationPickerProps) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { handleError } = useErrorPopup()

  const fetchNodes = useCallback(
    async (startAfter?: string) => {
      if (!source?.oid || !open) return

      dispatch({ type: "SET_LOADING", payload: true })
      try {
        const response = await getNodes({
          sourceId: source.oid,
          parentId: state.currentPath || null,
          startAfter,
          limit: 25,
        })
        dispatch({
          type: "ADD_NODES",
          payload: {
            items: response.data.items.map((item) => ({
              ...item,
              // Timestamp gets serialized when returned from the API
              created_at: new Timestamp(
                item.created_at._seconds,
                item.created_at._nanoseconds,
              ) as FirebaseFirestore.Timestamp,
              updated_at: new Timestamp(
                item.updated_at._seconds,
                item.updated_at._nanoseconds,
              ) as FirebaseFirestore.Timestamp,
            })),
            nextCursor: response.data.nextCursor ?? undefined,
            hasMore: response.data.nextCursor !== null,
          },
        })
      } catch (error) {
        handleError({ error, prefix: "Failed to fetch nodes" })
        dispatch({ type: "SET_ERROR", payload: "Failed to fetch nodes" })
      } finally {
        dispatch({ type: "SET_LOADING", payload: false })
      }
    },
    [source?.oid, state.currentPath, open, handleError, dispatch],
  )

  const loadMore = useCallback(async () => {
    if (state.isLoading || !state.hasMore) return
    await fetchNodes(state.cursor)
  }, [fetchNodes, state.isLoading, state.hasMore, state.cursor])

  const handleFolderClick = useCallback((external_id: string) => {
    dispatch({ type: "SET_CURRENT_PATH", external_id })
  }, [])

  const handleSyncChange = useCallback((oid: string) => {
    dispatch({ type: "TOGGLE_SYNC", oid })
  }, [])

  const cancelChanges = useCallback(() => {
    dispatch({ type: "RESET_CHANGES" })
  }, [])

  // Reset and fetch nodes when path changes
  useEffect(() => {
    if (!open || !source?.nodes_synced_at) return
    void fetchNodes()
  }, [open, state.currentPath, source?.nodes_synced_at, fetchNodes])

  return {
    state,
    loadMore,
    handleFolderClick,
    handleSyncChange,
    cancelChanges,
  }
}
