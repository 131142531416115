import { useCallback, useEffect, useState } from "react"

import { getOauthStateParam } from "../../auth/api"
import { GOOGLE_DRIVE_DOCUMENTS_SCOPES } from "../../constants"
import {
  getUserCredentials,
  removeUserCredentialEntry,
} from "../../credentials/api"
import {
  ATLASSIAN_PROVIDER,
  type CredentialMetadata,
  GONG_PROVIDER,
  NOTION_PROVIDER,
  SALESFORCE_PROVIDER,
  ZOOM_PROVIDER,
} from "../../credentials/providerTypes"
import useGoogleAccessToken from "./../useGoogleAccessToken"
import { type OAUTH_PROVIDERS, doOauthFlow } from "./doOauthFlow"

const useUserCredential = () => {
  const [loadingError, setLoadingError] = useState<Error>()
  const [actionError, setActionError] = useState<Error>()
  const [loading, setLoading] = useState<boolean>(true)
  const [userCredentials, setUserCredentials] = useState<CredentialMetadata[]>(
    [],
  )
  const { getAccessToken: getGoogleAccessToken } = useGoogleAccessToken(
    GOOGLE_DRIVE_DOCUMENTS_SCOPES,
  )

  const fetchData = useCallback(async () => {
    setLoading(true)
    setLoadingError(undefined)
    setActionError(undefined)
    try {
      const { data } = await getUserCredentials({})
      setUserCredentials(data.credentials)
    } catch (error) {
      if (!(error instanceof Error)) {
        throw error
      }
      setLoadingError(error)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    void fetchData()
  }, [fetchData])

  const removeCredentialEntry = useCallback(
    async (metadata: CredentialMetadata) => {
      setLoading(true)
      setActionError(undefined)
      try {
        await removeUserCredentialEntry({
          provider: metadata.provider,
          subProvider: metadata.subProvider,
        })
        await fetchData()
      } catch (error) {
        if (!(error instanceof Error)) {
          throw error
        }
        setActionError(error)
      } finally {
        setLoading(false)
      }
    },
    [fetchData],
  )

  const connectGoogleDrive = useCallback(async () => {
    setLoading(true)
    setActionError(undefined)
    try {
      await getGoogleAccessToken()
      await fetchData()
    } catch (error) {
      if (!(error instanceof Error)) {
        throw error
      }
      console.error("Could not connect Google Drive", error)
      setActionError(error)
    } finally {
      setLoading(false)
    }
  }, [fetchData, getGoogleAccessToken])

  const connectProvider = useCallback(
    async (provider: OAUTH_PROVIDERS) => {
      setLoading(true)
      setActionError(undefined)
      try {
        const { data } = await getOauthStateParam()
        await doOauthFlow(provider, data)
        await fetchData()
      } catch (error) {
        if (!(error instanceof Error)) {
          throw error
        }
        console.error("Could not connect provider", provider, error)
        setActionError(error)
      } finally {
        setLoading(false)
      }
    },
    [fetchData],
  )

  const connectGong = useCallback(
    () => connectProvider(GONG_PROVIDER),
    [connectProvider],
  )

  const connectZoom = useCallback(
    () => connectProvider(ZOOM_PROVIDER),
    [connectProvider],
  )

  const connectSalesforce = useCallback(
    () => connectProvider(SALESFORCE_PROVIDER),
    [connectProvider],
  )

  const connectNotion = useCallback(
    () => connectProvider(NOTION_PROVIDER),
    [connectProvider],
  )

  const connectAtlassian = useCallback(
    () => connectProvider(ATLASSIAN_PROVIDER),
    [connectProvider],
  )

  return {
    loadingError,
    actionError,
    loading,
    userCredentials,
    removeCredentialEntry,
    connectGoogleDrive,
    connectGong,
    connectZoom,
    connectSalesforce,
    connectNotion,
    connectAtlassian,
  }
}

export default useUserCredential
