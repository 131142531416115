import Button from "antd/es/button"
import Checkbox from "antd/es/checkbox"
import Empty from "antd/es/empty"
import Input from "antd/es/input"
import Tooltip from "antd/es/tooltip"
import { CheckIcon, LinkIcon, SearchIcon, TrashIcon, XIcon } from "lucide-react"
import { useCallback, useMemo, useState } from "react"
import { VList } from "virtua"

import { useActiveUserAuthorizationFromContext } from "../../contexts/ActiveUserAuthorizationContext"
import useComponentHeight from "../../hooks/useComponentHeight"
import useErrorPopup from "../../hooks/useErrorPopup"
import { useFeedFilters } from "../../hooks/useQuestionnaireFeedFilters"
import useSearchParamValue from "../../hooks/useSearchParamValue"
import { removeQuestionnaireJobAnswers } from "../../pages/QuestionnaireAssistant/api"
import type { AnswerQuestionnaireJob } from "../../types/jobs"
import { getUserFacingErrorType } from "../../userFacingErrorHelpers"
import { sleep } from "../../utils"
import AssignQuestionnaireAnswerButton from "../AssignQuestionnaireAnswerForm"
import BulkCommentButton from "../BulkCommentButton"
import FeedFilterPopover from "./FeedFilterPopover"
import QuestionnaireReviewFeedCard from "./QuestionnaireReviewFeedCard"
import SortByButton from "./SortByButton"
import type { AnswerWithDiscussion } from "./types"
import { isApproved, reviewAnswer } from "./utils"

interface QuestionnaireReviewFeedProps {
  answers: AnswerWithDiscussion[]
  job: AnswerQuestionnaireJob
  discussionsError?: Error
}

const sortByDefault = (
  a: AnswerWithDiscussion,
  b: AnswerWithDiscussion,
): number => {
  const aLoc = a.primary_question?.location
  const bLoc = b.primary_question?.location
  if (!aLoc || !bLoc) {
    return 0
  }
  if (aLoc.sheetName !== bLoc.sheetName) {
    return aLoc.sheetName! < bLoc.sheetName! ? -1 : 1
  }

  return aLoc.firstRowIndex > bLoc.firstRowIndex
    ? 1
    : aLoc.firstRowIndex === bLoc.firstRowIndex
      ? 0
      : -1
}

const sortByPriority = (
  a: AnswerWithDiscussion,
  b: AnswerWithDiscussion,
): number => {
  // Show in lexical order:
  //  (no text, edited or reviewed, confidence, document order)
  const hasTextDiff =
    (a.primary_answer.text !== "" ? 1 : 0) -
    (b.primary_answer.text !== "" ? 1 : 0)
  if (hasTextDiff !== 0) {
    return hasTextDiff
  }

  const editedStatusDiff =
    (a.last_edited_by || a.last_reviewed_by ? 1 : 0) -
    (b.last_edited_by || b.last_reviewed_by ? 1 : 0)
  if (editedStatusDiff !== 0) {
    return editedStatusDiff
  }

  const confidenceDiff = a.confidence - b.confidence
  if (confidenceDiff !== 0) {
    return confidenceDiff
  }

  return sortByDefault(a, b)
}

const sortByConfidence = (
  a: AnswerWithDiscussion,
  b: AnswerWithDiscussion,
): number => {
  const confidenceDiff = b.confidence - a.confidence // High to low
  return confidenceDiff !== 0 ? confidenceDiff : sortByDefault(a, b)
}

const QuestionnaireReviewFeed: React.FC<QuestionnaireReviewFeedProps> = ({
  answers,
  job,
  discussionsError,
}) => {
  const {
    filterAnswers,
    filterState,
    resetFilters,
    setFilterValue,
    activeFilterCount,
  } = useFeedFilters()
  const [searchTerm, setSearchTerm] = useState("")
  const [sortByKind, setSortByKind] = useSearchParamValue<
    "priority" | "confidence"
  >("sortBy")
  const [selectedAnswerOids, setSelectedAnswerOids] = useState<Set<string>>(
    new Set(),
  )
  const { handleError, handleSuccess } = useErrorPopup()
  const [approving, setApproving] = useState(false)
  const [removing, setRemoving] = useState(false)
  const { authUser } = useActiveUserAuthorizationFromContext()
  const [updatingAnswer, setUpdatingAnswer] = useState(false)

  const prefilteredAnswers = useMemo(() => {
    const sorters = {
      priority: sortByPriority,
      confidence: sortByConfidence,
      default: sortByDefault,
    }
    return filterAnswers(answers).sort(sorters[sortByKind ?? "default"])
  }, [answers, filterAnswers, sortByKind])

  const searchStrings = useMemo(() => {
    return prefilteredAnswers.map((a) =>
      [
        a.primary_question.text.toLowerCase(),
        a.secondary_question?.text.toLowerCase() ?? "",
        a.primary_answer.text.toLowerCase(),
        a.secondary_answer?.text.toLowerCase() ?? "",
        a.question_id?.toLowerCase() ?? "",
      ].join("%%"),
    )
  }, [prefilteredAnswers])

  const filteredAnswers = useMemo(() => {
    if (searchTerm === "") return prefilteredAnswers
    const searchLower = searchTerm.toLowerCase()
    return prefilteredAnswers.filter((_, i) =>
      searchStrings[i].includes(searchLower),
    )
  }, [prefilteredAnswers, searchStrings, searchTerm])

  const selectedAnswers = useMemo(
    () => filteredAnswers.filter((a) => selectedAnswerOids.has(a.oid)),
    [filteredAnswers, selectedAnswerOids],
  )

  const assignedTo = useMemo(() => {
    if (selectedAnswers.length === 0) return undefined

    const firstAssignedTo = selectedAnswers[0]?.last_assigned_to

    return selectedAnswers.every(
      (a) => a.last_assigned_to?.uid === firstAssignedTo?.uid,
    )
      ? firstAssignedTo
      : undefined
  }, [selectedAnswers])

  const filteredAnswerIndices = useMemo(() => {
    const result = new Map<string, number>()
    for (const [i, a] of filteredAnswers.entries()) {
      result.set(a.oid, i)
    }
    return result
  }, [filteredAnswers])

  const handleSelect = useCallback(
    (answer: AnswerWithDiscussion, event: React.MouseEvent) => {
      const index = filteredAnswerIndices.get(answer.oid)
      if (index === undefined) return

      setSelectedAnswerOids((prev) => {
        const newSet = new Set(prev)
        if (event.shiftKey && prev.size > 0) {
          document.getSelection?.()?.removeAllRanges()
          const lastSelectedIndex = filteredAnswers.findIndex((a) =>
            prev.has(a.oid),
          )
          if (lastSelectedIndex !== -1) {
            const start = Math.min(lastSelectedIndex, index)
            const end = Math.max(lastSelectedIndex, index)
            for (let i = start; i <= end; i++) {
              newSet.add(filteredAnswers[i].oid)
            }
          } else {
            newSet.add(answer.oid)
          }
        } else {
          if (newSet.has(answer.oid)) {
            newSet.delete(answer.oid)
          } else {
            newSet.add(answer.oid)
          }
        }
        return newSet
      })
    },
    [filteredAnswers, filteredAnswerIndices],
  )

  const handleSelectAll = useCallback(() => {
    setSelectedAnswerOids((prev) =>
      prev.size === 0 ? new Set(filteredAnswers.map((a) => a.oid)) : new Set(),
    )
  }, [filteredAnswers])

  const handleApproveAll = useCallback(async () => {
    try {
      setApproving(true)

      await reviewAnswer("REVIEW", job.oid, selectedAnswers)
      handleSuccess("Selected answers approved successfully!")
      setSelectedAnswerOids(new Set())
    } catch (error) {
      if (getUserFacingErrorType(error) === "OUT_OF_DATE") {
        handleError({
          error,
          message: (
            <>
              One of the answers is out of date.
              <br />
              Save the update or refresh and reread to approve
            </>
          ),
          duration: 6000,
        })
      } else {
        handleError({ error, prefix: "Couldn't approve selected answers" })
      }
    } finally {
      setApproving(false)
    }
  }, [selectedAnswers, job.oid, handleSuccess, handleError])

  const handleUnapproveAll = useCallback(async () => {
    setApproving(true)
    try {
      await reviewAnswer("UNREVIEW", job.oid, selectedAnswers)
      handleSuccess("Selected answers unapproved successfully!")
      setSelectedAnswerOids(new Set())
    } catch (error) {
      handleError({ error, prefix: "Couldn't unapprove selected answers" })
    }
    setApproving(false)
  }, [selectedAnswers, job.oid, handleSuccess, handleError])

  const handleRemoveAll = useCallback(async () => {
    setRemoving(true)
    try {
      await removeQuestionnaireJobAnswers(
        job.oid,
        selectedAnswers.map((a) => ({
          job_oid: job.oid,
          answer_oid: a.oid,
        })),
      )
      handleSuccess("Selected answers removed successfully!")
      setSelectedAnswerOids(new Set())
    } catch (error) {
      handleError({ error, prefix: "Couldn't remove selected answers" })
    } finally {
      setRemoving(false)
    }
  }, [selectedAnswers, job.oid, handleSuccess, handleError])

  const filterBySelection = useCallback(async () => {
    // TODO(mgraczyk): Remove?
    const answerOids = Array.from(selectedAnswerOids)
    setFilterValue("oid", answerOids)
    // Wait for the filter to be applied.
    await sleep(1)
    await navigator.clipboard.writeText(window.location.href)
    handleSuccess("Copied shareable link to clipboard!")
  }, [selectedAnswerOids, handleSuccess, setFilterValue])

  const answerLocations = useMemo(
    () =>
      Array.from(selectedAnswerOids).map(
        (oid) =>
          filteredAnswers.find((a) => a.oid === oid)?.primary_answer.location,
      ),
    [selectedAnswerOids, filteredAnswers],
  )

  const [listRef, listHeight] = useComponentHeight()

  const isOwnerOfSelected =
    job.creator.uid === authUser.uid ||
    selectedAnswers.every(
      (answer) => answer?.last_assigned_to?.uid === authUser.uid,
    )
  const numApprovedAnswers = filteredAnswers.filter(isApproved).length
  const nothingSelected = selectedAnswerOids.size === 0
  const allSelectedNotApproved = selectedAnswers.every((a) => !isApproved(a))
  const changing = approving || removing

  return (
    <div className="flex w-full flex-col">
      <div className="border-gray-25 w-full border-b bg-white pb-4">
        <div className="my-4 mr-6 flex items-center gap-2">
          <Input
            placeholder="Search questions and answers"
            prefix={<SearchIcon />}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="grow"
          />
          <FeedFilterPopover
            answers={answers}
            setFilterValue={setFilterValue}
            filterState={filterState}
            resetFilters={resetFilters}
            activeFilterCount={activeFilterCount}
          />
          <SortByButton sortByKind={sortByKind} setSortByKind={setSortByKind} />
        </div>
        <div className="flex items-center">
          <div className="flex grow flex-wrap items-center gap-x-2 gap-y-2">
            <Checkbox
              checked={
                filteredAnswers.length > 0 &&
                selectedAnswerOids.size === filteredAnswers.length
              }
              indeterminate={
                selectedAnswerOids.size > 0 &&
                selectedAnswerOids.size < filteredAnswers.length
              }
              onChange={handleSelectAll}
            >
              Select All
            </Checkbox>
            <span className="ml-2 mr-2 min-w-[6rem] max-w-[6rem] text-gray-600">
              {selectedAnswerOids.size} selected
            </span>

            <Button
              size="small"
              type="primary"
              icon={<CheckIcon />}
              onClick={handleApproveAll}
              disabled={
                changing ||
                updatingAnswer ||
                nothingSelected ||
                selectedAnswers.every(isApproved)
              }
            >
              Approve
            </Button>
            <Tooltip
              title={
                nothingSelected
                  ? "Select items to remove approval"
                  : allSelectedNotApproved
                    ? "None of the selected answers are approved"
                    : "Remove approval from selected answers"
              }
            >
              <Button
                size="small"
                icon={<XIcon />}
                onClick={handleUnapproveAll}
                disabled={
                  changing ||
                  updatingAnswer ||
                  nothingSelected ||
                  allSelectedNotApproved
                }
              >
                Unapprove
              </Button>
            </Tooltip>
            <BulkCommentButton
              small
              jobOid={job.oid}
              answerLocations={answerLocations}
            />
            <AssignQuestionnaireAnswerButton
              jobOid={job.oid}
              answerOids={Array.from(selectedAnswerOids)}
              assignedTo={assignedTo}
              disabled={!isOwnerOfSelected || changing || updatingAnswer}
              small
            />
            <Tooltip
              title={
                isOwnerOfSelected
                  ? "Remove selected answers"
                  : "You are not the owner of these items"
              }
            >
              <Button
                size="small"
                danger
                icon={<TrashIcon />}
                onClick={handleRemoveAll}
                disabled={
                  changing ||
                  updatingAnswer ||
                  nothingSelected ||
                  !isOwnerOfSelected
                }
              />
            </Tooltip>
            <Button
              size="small"
              icon={<LinkIcon />}
              onClick={filterBySelection}
              disabled={changing || nothingSelected}
            >
              Link
            </Button>
          </div>

          <div className="ml-auto mr-4 flex items-center">
            <span className="mr-2 font-semibold text-gray-700">
              {numApprovedAnswers} / {filteredAnswers.length} approved
            </span>
          </div>
        </div>
      </div>
      <div ref={listRef} className="grow overflow-y-hidden">
        {filteredAnswers.length === 0 && (
          <Empty
            className="m-auto flex h-96 flex-col items-center justify-center rounded-md border"
            description={
              activeFilterCount > 0
                ? "No answers match the selected filters"
                : "No answers available"
            }
          />
        )}
        <VList style={{ height: listHeight }}>
          {filteredAnswers.map((answer: AnswerWithDiscussion) => (
            <QuestionnaireReviewFeedCard
              key={answer.oid}
              answer={answer}
              job={job}
              setUpdatingAnswer={setUpdatingAnswer}
              discussionsError={discussionsError}
              focused={filterState.oid.includes(answer.oid)}
              isSelected={selectedAnswerOids.has(answer.oid)}
              onSelect={handleSelect}
              loading={changing && selectedAnswerOids.has(answer.oid)}
            />
          ))}
        </VList>
      </div>
    </div>
  )
}

export default QuestionnaireReviewFeed
